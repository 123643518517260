import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

import { 
  logoSVGT1, 
    logoSVGT2,
    logoSVGT3, 
    logoSVGT4, 
    logoSVGT5, 
    logoSVGT6, 
    logoSVGT7, 
  calendarSVGT1, 
    calendarSVGT2, 
    calendarSVGT3,
    calendarSVGT4,
    calendarSVGT5,
    calendarSVGT6, 
  locationSVGT, 
} from '../../static/svgtext'
import { 
  ContainerSVGT,
  MainFont,
  SubFont,
  HomeColor, 
  AboutColor, 
  EventsColor, 
  LiveColor, 
  OLDColor, 
  MinistriesColor,
  GivingColor,
  ContactColor, 
  MinistriesName,
  ThirdPage,
} from '../components/Sampler'

export const IndexPageTemplate = ({
  sunrise,
  backgroundimage,
  welcometitle,
  welcomeinfo,
  welcomecontainerimage,
  calendarcontainerimage,
  locationcontainerimage,
  weeklycalendar
}) => (
  <div>
    <div
      className="full-width-image video-frame"
      style={{
        backgroundImage: sunrise ? null : `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
          })`,
      }}
    >
      {/* Font Imports */}
      <link
        href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
        rel="stylesheet"
      ></link>
      <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.cdnfonts.com/css/bahnschrift" rel="stylesheet"></link>

      {sunrise ?
        <iframe class="videobgframe" frameborder="0" seamless="" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="https://player.vimeo.com/video/68482950?background=1&amp;api=1&amp;autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;muted=1&amp;player_id=videobgframe-1243667655" id="videobgframe-1243667655"></iframe>
      : 
        null
      }
      <div
        style={{
          display: 'flex',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
          width: ''
        }}
      >
        {/* BLANK WHITE CIRCLE SVG */}
        <div style={{height: '300px'}}>
          <div style={{
            position: 'absolute',
            marginTop: '82px',
            width: '100%',
            left: '0px',
            textAlign: 'center',
            fontSize: '2.8em',
            lineHeight: '50px',
            color: 'white',
            fontFamily: MainFont,
            textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
            fontWeight: 'bold',
          }}>
            Onley<br />Baptist<br />Church
          </div>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="310px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill={HomeColor} stroke="none">
              <path d={ContainerSVGT}/>
            </g>
          </svg>
        </div>
      </div>
    </div>

    {/* WELCOME / UPDATE BOX */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        boxShadow: '0 1em 1.3em -1em rgba(0,0,0,.1)',
        position: 'relative',
        zIndex: '3'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{display: 'flex', marginTop: '20px', marginBottom: '20px'}}>

        {/* LOGO SVG */}
        <div style={{ display: 'grid', margin: '29px', gridTemplateColumns: '208px', height: '206px'}}>

          {/* Image Style Container */}
          <img src={`${!!welcomecontainerimage.childImageSharp ? welcomecontainerimage.childImageSharp.fluid.src : welcomecontainerimage}`} alt="Test Image"
            style={{
              height: '202.72px',
              width: '100%',
              objectFit: 'cover',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              maskImage: `url("/img/Blank White Circle.svg")`,
              maskRepeat: 'no-repeat',
              maskPosition: 'center',
              filter: 'brightness(70%)',
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              zIndex: '-2',
            }}
          />

          {/* Logo SVG */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="160.72px" viewBox="0 0 1140.000000 1158.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              marginLeft: '45px',
              marginTop: '17px'
            }}>

            <g transform="translate(0.000000,1158.000000) scale(0.100000,-0.100000)"
              fill='white' stroke="none">
              <path d={logoSVGT1} />
              <path d={logoSVGT2} />
              <path d={logoSVGT3} />
              <path d={logoSVGT4} />
              <path d={logoSVGT5} />
              <path d={logoSVGT6} />
              <path d={logoSVGT7} />
            </g>
          </svg>

        </div>

        {/* Text */}
        <div className="boxText" style={{margin: '30px'}}>

          {/* Title */}
          <h1 className="title" 
            style={{ 
              fontSize: '2.4em',
              color: HomeColor,
              fontFamily: MainFont,
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null
            }}
          >
            {/* A Place You Can Call Home */}
            {welcometitle}
          </h1>

          {/* Information */}
          <p style={{
              fontSize: '1.1em',
              fontWeight: 'bold',
              lineHeight: '2em',
              letterSpacing: '0.06em',
              fontFamily: SubFont,
              color: HomeColor,
            }}
          >
            {welcomeinfo}
          </p>
        </div>

      </div>
    </div>

    {/* A WEEK WITH {CHURCH} */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        boxShadow: '0 1em 1.3em -1em rgba(0,0,0,.1)',
        position: 'relative',
        zIndex: '2'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{ justifyContent: 'center', display: 'flex', marginTop: '20px', marginBottom: '20px' }}>

        {/* Text */}
        <div style={{ margin: '30px' }}>

          {/* Title */}
          <h1 className="title"
            style={{
              fontSize: '2.4em',
              color: HomeColor,
              fontFamily: MainFont,
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
            }}
          >
            A Week with OBC
          </h1>

          {/* Information */}
          <p
            style={{
              fontSize: '1.1em',
              lineHeight: '2em',
              letterSpacing: '0.06em',
              fontFamily: SubFont,
              color: HomeColor,
            }}
          >
            {weeklycalendar.map((item) => (
              <div>
                <b>{item.weekday + ', '}</b>
                {item.time + ': '}
                <i>{item.event}</i>
                <br />
              </div>
            ))}
          </p>
        </div>

        {/* CALENDAR SVG */}
        <div style={{ display: 'grid', margin: '29px', gridTemplateColumns: '208px', height: '206px' }}>

          {/* Image Style Container */}
          <img src={`${!!calendarcontainerimage.childImageSharp ? calendarcontainerimage.childImageSharp.fluid.src : calendarcontainerimage}`} alt="Test Image"
            style={{
              height: '202.72px',
              width: '100%',
              objectFit: 'cover',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              maskImage: `url("/img/Blank White Circle.svg")`,
              maskRepeat: 'no-repeat',
              maskPosition: 'center',
              filter: 'brightness(70%)',
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              zIndex: '-2'
            }}
          />

          {/* Calendar Svg Image */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="202.72px" viewBox="0 0 443.000000 439.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              marginLeft: '3px',
              marginTop: '-3px'
            }}>

            <g transform="translate(0.000000,436.000000) scale(0.100000,-0.100000)"
              fill='white' stroke="none">
              <path d={calendarSVGT1} />
              <path d={calendarSVGT2} />
              <path d={calendarSVGT3} />
              <path d={calendarSVGT4} />
              <path d={calendarSVGT5} />
              <path d={calendarSVGT6} />
            </g>
          </svg>
        </div>
      </div>
    </div>

    {/* WHERE TO FIND US */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '30px',
        boxShadow: '0 1em 1.3em -1em rgba(0,0,0,.1)',
        position: 'relative',
        zIndex: '1'
        // marginBottom: '30px'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{ display: 'flex', marginTop: '40px', marginBottom: '20px' }}>

        {/* Text */}
        <div style={{ textAlign: 'center', margin: '30px' }}>

          {/* Title */}
          <h1 className="title"
            style={{

              fontSize: '2.4em',
              color: HomeColor,
              fontFamily: MainFont,
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null
            }}
          >
            Where to Find Us
          </h1>

          {/* LOCATION SVG */}
          <div style={{ display: 'grid', justifyContent: 'center', gridTemplateColumns: '208px', height: '206px' }}>

            {/* Image Style Container */}
            <img src={`${!!locationcontainerimage.childImageSharp ? locationcontainerimage.childImageSharp.fluid.src : locationcontainerimage}`} alt="Test Image"
              style={{
                height: '202.72px',
                width: '100%',
                objectFit: 'cover',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                maskImage: `url("/img/Blank White Circle.svg")`,
                maskRepeat: 'no-repeat',
                maskPosition: 'center',
                filter: 'brightness(70%)',
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
                zIndex: '-2'
              }}
            />

            {/* Location Svg Image */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              height="202.72px" viewBox="0 0 443.000000 439.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
                marginLeft: '3px',
                marginTop: '-3px'
              }}>

              <g transform="translate(0.000000,436.000000) scale(0.100000,-0.100000)"
                fill='white' stroke="none">
                <path d={locationSVGT} />
              </g>
            </svg>
          </div>

        </div>
        
        {/* Google Maps */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5465.375533242996!2d-75.72164644382347!3d37.691074256391126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ba2cab015156a3%3A0xdb3646a02f80d3ef!2sOnley%20Baptist%20Church!5e0!3m2!1sen!2sus!4v1700072819097!5m2!1sen!2sus" style={{
            allowfullscreen: 'true',
            loading: 'lazy',
            width: '100%',
            height: '400px',
            border: '0',
            marginLeft: '30px',
            marginRight: '30px'
          }}
        />
      </div>
    </div>

    <section className="section section--gradient" style={{backgroundColor: 'white'}}>

      <div className="container">
        
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            // width: '70%'
          }}
        >

          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
            
            {/* ABOUT Link Button */}
            <Link to="/about" title="About">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >
                
                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={AboutColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "About" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontWeight: 'lighter',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  ABOUT
                </div>
              </div>
            </Link>

            {/* EVENTS Link Button */}
            <Link to="/events" title="Events">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={EventsColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Events" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  EVENTS
                </div>
              </div>            
            </Link>

            {/* LIVE Link Button */}
            <Link to="/sermons" title="Live">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={LiveColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Live" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  {ThirdPage === "Livestream (Facebook)" || ThirdPage === "Livestream (YouTube)" ? "LIVE" : ThirdPage === "Video (Facebook)" || ThirdPage === "Video (YouTube)" ? "SERMONS" : "HISTORY"}
                </div>
              </div>            
            </Link>

            {/* MINISTRIES Link Button */}
            <Link to="/ministries" title="Ministries">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={MinistriesColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Ministries" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  <p style={{marginBottom: '-10px'}}>GET</p>
                  <p style={{ letterSpacing: '-0.2px'}}>INVOLVED</p>
                </div>
              </div>            
            </Link>

            {/* GIVING Link Button */}
            <Link to="/giving" title="Giving">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px' }} >

                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={GivingColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>
                
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  GIVING
                </div>
              </div>
            </Link>

            {/* CONTACT Link Button */}
            <Link to="/contact" title="Contact Us">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={ContactColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Contact" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  CONTACT
                </div>
              </div>            
            </Link>

          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  sunrise: PropTypes.bool,
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  welcometitle: PropTypes.string,
  welcomeinfo: PropTypes.string,
  welcomecontainerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  calendarcontainerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  locationcontainerimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  weeklycalendar: PropTypes.array
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        sunrise={frontmatter.sunrise}
        backgroundimage={frontmatter.backgroundimage}
        welcometitle={frontmatter.welcometitle}
        welcomeinfo={frontmatter.welcomeinfo}
        welcomecontainerimage={frontmatter.welcomecontainerimage}
        calendarcontainerimage={frontmatter.calendarcontainerimage}
        locationcontainerimage={frontmatter.locationcontainerimage}
        weeklycalendar={frontmatter.weeklycalendar}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        sunrise
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        welcometitle
        welcomeinfo
        welcomecontainerimage {
          childImageSharp {
            fluid(maxWidth: 1075, quality: 72) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        calendarcontainerimage {
          childImageSharp {
            fluid(maxWidth: 1075, quality: 72) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        locationcontainerimage {
          childImageSharp {
            fluid(maxWidth: 1075, quality: 72) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        weeklycalendar {
          weekday
          time
          event
        }
      }
    }
  }
`
